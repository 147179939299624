import React from "react";
import { Container, Typography, Box } from "@mui/material";
import ContactInfo from "../components/ContactInfo";
import TextImageSection from "../components/TextImageSection";
import carImage from "../assets/bil_420x320.jpg";
import underlagg from "..//assets/underlagg.jpg";
import construction from "..//assets/construction.jpg";

const Home = () => {
  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: 400,
          backgroundImage: `url(${construction})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          marginBottom: 2,
        }}
      />
      <Container>
        <Typography variant="body1">
          Företaget grundades hösten 2019 av Magnus Gustafsson och John Smith.
          Tillsammans har vi över 30 års erfarenhet inom branschen, där våra
          karriärer tog sin början redan i början av 2000-talet.
        </Typography>

        <ContactInfo
          phone="+46 73 612 06 40"
          email="info@kintab.se"
          address="Grundbergsvägen 15, 461 39 Trollhättan"
          googlemaps="https://maps.app.goo.gl/LihMvaA1NuoajQfz9"
          phone2="+46 73 021 73 90"
        />
        <TextImageSection
          title="Våra tjänster"
          text="Vi erbjuder ett brett utbud av tjänster inom både el och bygg, med en lösning för varje behov. Oavsett om det gäller installation av elbilsladdare, uppbyggnad av ställverk, eller helt nya byggprojekt, har vi kompetensen och erfarenheten att ta oss an alla typer av uppdrag. Vi är också specialiserade på finsnickeri, där vi skapar detaljerade och hållbara lösningar som matchar våra kunders visioner.

Vårt team arbetar med högsta precision och engagemang för att säkerställa att varje projekt genomförs enligt de senaste branschstandarderna. Vi strävar alltid efter att leverera effektiva och hållbara lösningar för både små och stora projekt, oavsett om det handlar om renovering, nybyggnation eller installation av avancerad elektrisk utrustning. Vi värdesätter kundnära samarbete och strävar efter att skapa långsiktiga relationer genom att erbjuda pålitliga och skräddarsydda tjänster."
          imageUrl={carImage}
          imageFirst={true} // Bilden visas till vänster
        />
        <TextImageSection
          title="Om oss"
          text="Vårt mål är att erbjuda helhetsbaserade lösningar som möter dina behov och överträffar dina förväntningar. Vi är din pålitliga partner för projektering, konsultuppdrag, service och installation, och vi fokuserar alltid på att leverera högsta kvalitet inom både el- och byggbranschen. Genom att arbeta nära våra kunder kan vi säkerställa skräddarsydda lösningar som är effektiva, hållbara och kostnadseffektiva.

Vi har lång erfarenhet och gedigen expertis, och är dessutom certifierade för att utföra högspänningsinstallationer upp till 130 kV, vilket gör oss till en pålitlig aktör för komplexa och tekniskt avancerade projekt. Oavsett om det gäller små eller stora uppdrag, kan du lita på att vårt team av specialister hanterar varje steg av processen med största noggrannhet och engagemang. Vi är stolta över att erbjuda en komplett tjänsteportfölj som täcker allt från planering och design till installation och efterservice."
          imageUrl={underlagg}
          imageFirst={false} // Bilden visas till höger
        />
      </Container>
    </div>
  );
};

export default Home;
